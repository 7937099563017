import React, { useState, useEffect, useMemo } from "react";
import { over } from "stompjs";
import SockJS from "sockjs-client";
import { Button, IconButton, makeStyles, Tooltip, Zoom } from "@material-ui/core";
import { ApplicationStyle } from "../../../theme";
import { useStateProfessionalDashboardValue } from "../../../context/profesionalPage/profesionalDashboardState";
import {
  getPatientsQueue,
  getPatientsQueueOther,
} from "../../../services/queueService";
import HeaderImage from "../../../assets/img/medicos_disponibles.svg";
import { useStateAuthValue } from "../../../context/Auth/AuthState";
import { useToast } from "../../../context/Toast/ToastProvider";
import { sendDesktopNotification } from "../../../utils/DesktopNotification";
import {
  checkStatus,
  processEmail,
  showToast,
  ToastType,
} from "../../../utils/ToastUtil";
import LoadingSpinner from "../../../components/LoadingSpinner";
import connectedToPatientSound from "../../../assets/notifications/new-patient.wav";
import patientOnQueueSound from "../../../assets/notifications/sweet_text.mp3";
import Modal from "../../../components/Modal";
import { postProfessionalNotifications } from "../../../services/notificationService";
import { EventLog } from "../../../services/medilinePrepaidService";
import { sessionProfileGet } from "../../../services/authService";
import CustomizedTables from "../../../components/FormFields/CustomizedTables";
import {
  formatDateHourAndMinute,
  formatDateTime,
} from "../../../utils/DateUtil";
import CallOutlined from "@material-ui/icons/CallOutlined";
import firebase from "../../../services/firebase";
import { Send, Cached } from "@material-ui/icons";
import ChatOverlaySend from "../../chatSend/ChatOverlaySend";
import { Description } from "@material-ui/icons";
import Consultation from "../VideoCall/ActionPanel/Consultation";
import { AllowIncomingCallSound } from "../../../utils/AllowIncomingCallSound";

// websocket url for connection
const UrlWS = process.env.REACT_APP_WS_URL;
let stompClient = null;

const useStyles = makeStyles((theme) => ({
  submitGetPatient: {
    backgroundColor: "#002C54",
    "&:hover": {
      backgroundColor: "#00396e",
    },
    margin: theme.spacing(3, 0, 2),
    borderRadius: "20px",
    textTransform: "capitalize",
  },
  submitCancel: {
    backgroundColor: "#DA2037",
    "&:hover": {
      backgroundColor: "#e84353",
    },
    widht: "11rem",
    margin: theme.spacing(3, 0, 2),
    borderRadius: "20px",
    textTransform: "capitalize",
  },
}));

const VirtualGuard = ({
  setVirtualGuardData,
  setVideoCall,
  videoCall,
  setOnConsult,
  hasPermission,
  setAskPermission,
  realoadComponent,
  setIsAllowed,
  isAllowed,
}) => {
  const classes = useStyles();
  const [, dispatch] = useStateProfessionalDashboardValue();
  const toastDispatch = useToast();
  const [{ userData, userToken }] = useStateAuthValue();
  const [privatemessagews, setPrivatemessagews] = useState(new Map());
  const [openPatientModal, setOpenPatientModal] = useState(false);
  const MedilineStyle = makeStyles(ApplicationStyle);
  const MedilineClasses = MedilineStyle();
  const [patientQueue, setPatientQueue] = useState([]);
  const [patientQueueOther, setPatientQueueOther] = useState([]);
  //patient state flags
  const [patientFound, setPatientFound] = useState(false);
  const [emptyQueue, setEmptyQueue] = useState(false);
  const [lookingForPatient, setLookingForPatient] = useState(false);
  const [patientCancelled, setPatientCancelled] = useState(false);
  const [patientOnWaitList, setPatientOnWaitList] = useState(false);
  const [queue, setQueue] = useState(null);
  const [queueOther, setQueueOther] = useState(null);
  const [queueLength, setQueueLength] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [openTimeout, setOpenTimeout] = useState(false);
  const doctorName = userData?.first_name + " " + userData?.last_name;
  const [command, setCommand] = useState("");
  const [loadingQueue, setLoadingQueue] = useState(false);
  const [enableCancelProfessional, setEnableCancelProfessional] =
    useState(false);
  const [patientData, setPatientData] = useState({
    patientId: "",
    professionalId:
      userData?.professional_dto?.auth_id ||
      sessionProfileGet()?.userData.professional_dto?.auth_id,
    profesionalName: "",
    profImage: "",
    profSpecialty: "",
    reason: "",
    specialtyId:
      userData?.professional_dto?.specialties[0]?.id ||
      sessionProfileGet()?.userData.professional_dto?.specialties[0]?.id,
    queueId: "",
    connected: true,
    orderNumber: "",
  });
  const [call, setCall] = useState(null);
  const [send, setSend] = useState(null);
  const [consultation, setConsultation] = useState(null);
  const [openConsultation, setOpenConsultation] = useState(false);
  const [patientConsultation, setPatientConsultation] = useState(null);
  const [openChatSend, setOpenChatSend] = useState(false);
  const [patientSend, setPatientSend] = useState(null);
  let REACT_APP_SEND_PATIENT = process.env.REACT_APP_SEND_PATIENT === "true";
  let REACT_APP_SEND_CONSULTATION =
    process.env.REACT_APP_SEND_CONSULTATION === "true";

  const playAudio = (audioFile) => {
    const audio = new Audio(audioFile);
    audio.addEventListener("canplaythrough", () => {
      audio.play();
    });
  };

  const leavePage = () => {
    setOnConsult(false);
    var wsMessage = {
      patientId: patientData?.patientId,
      orderNumber: patientData?.orderNumber,
      specialtyId: patientData?.specialtyId,
      command: "ProfessionalCancel",
      professionalId: patientData?.professionalId,
      queueId: patientData?.queueId,
      date: patientData?.date,
    };
    firebase.put({ ...wsMessage, status: "QUIT" });
    stompClient?.send("/app/message", {}, JSON.stringify(wsMessage));
    localStorage.setItem("consult", null);
  };

  window.onbeforeunload = () => {
    if (enableCancelProfessional) leavePage();
  };

  const getProfileQueue = (queue) => {
    const patientsQueueData = [];
    queue.map((element) => {
      const patientData = {
        "Prof.": `${element?.professional_first_name ?? ""} ${
          element?.professional_last_name ?? ""
        }`,
        Nombre: element?.first_name + " " + element?.last_name,
        Correo: processEmail(element?.familymember),
        Financiador: element?.financer,
        "Tiempo de Espera/Hora": formatDateTime(element.created_date),
        Estado: checkStatus(element.status),
      };
      patientsQueueData.push(patientData);
    });
    setPatientQueue(patientsQueueData);
  };

  const getProfileQueueOther = (queue) => {
    const patientsQueueData = [];
    queue.map((element) => {
      const patientData = {
        "Prof.": `${element?.professional_first_name ?? ""} ${
          element?.professional_last_name ?? ""
        }`,
        Nombre: element?.first_name + " " + element?.last_name,
        Correo: processEmail(element?.familymember),
        Financiador: element?.financer,
        "Tiempo de Espera/Hora": formatDateHourAndMinute(element.created_date),
        Estado: checkStatus(element.status),
      };
      patientsQueueData.push(patientData);
    });
    setPatientQueueOther(patientsQueueData);
  };


  /*   useEffect(() => {
      if (openTimeout) {
        const timeout = setTimeout(() => {
          var wsMessage = {
            patientId: patientData?.patientId,
            orderNumber: patientData?.orderNumber,
            specialtyId: patientData?.specialtyId,
            command: 'ProfessionalTimeoutCancel',
            professionalId: patientData?.professionalId,
            queueId: patientData?.queueId,
            date: patientData?.date,
          };
          if(!videoCall){
            stompClient.send('/app/message', {}, JSON.stringify(wsMessage));
            localStorage.setItem('consult', null);
            setOnConsult(false);
            setDisabled(false);
          }
        }, 70000);
  
        return () => {
          clearTimeout(timeout);
        };
      }
    }, [openTimeout]); */

  // get specialty queue number for display
  useEffect(() => {
    if (userToken && userData) {
      getQueueInfo();
      getQueueInfoOther();
    }
    const intervalId = setInterval(async () => {
      getQueueInfo();
      getQueueInfoOther();
      //await getFirebase();
    }, 30000);
    return () => clearInterval(intervalId);
  }, [userData, userToken, patientCancelled, patientFound]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setQueueInfoEventLog();
    }, 120000);
    return () => clearInterval(intervalId);
  }, [userData, patientCancelled, patientFound]);

  // useEffect(() => {
  //   sendProfessionalLog();
  //   const intervalId = setInterval(() => {
  //     sendProfessionalLog();
  //   }, 15000);
  //   return () => clearInterval(intervalId);
  // }, [userData]);

  // sound notification check
  useEffect(() => {
    if (patientFound && !patientOnWaitList) {
      playAudio(connectedToPatientSound);
    }
    if (!patientFound && patientOnWaitList) {
      playAudio(patientOnQueueSound);
      if (openPatientModal) {
        const intervalId = setInterval(() => {
          playAudio(patientOnQueueSound);
        }, 3000);
        return () => clearInterval(intervalId);
      }
    }
  }, [patientFound, patientOnWaitList, openPatientModal]);

  // connect to ws
  useEffect(() => {
    if (userData?.professional_dto?.auth_id) {
      let Sock = new SockJS(UrlWS);
      stompClient = over(Sock);
      stompClient.connect({}, onConnectedProfessional, onError);

      const timeoutId = setTimeout(() => {
        setDisabled(false);
      }, 3000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [userData]);

  const getQueueAll = () => {
    getQueueInfo();
    getQueueInfoOther();
  }

  const setQueueInfoEventLog = async () => {
    await EventLog(userToken, "waiting call", "profesional", patientData);
  };

  const getQueueInfo = async () => {
    if (
      userData?.professional_dto?.specialties[0]?.id ||
      sessionProfileGet()?.userData.professional_dto?.specialties[0]?.id
    ) {
      setLoadingQueue(true);
      await getPatientsQueue(
        userToken,
        userData?.professional_dto?.specialties[0]?.id ||
          sessionProfileGet()?.userData.professional_dto?.specialties[0]?.id
      )
        .then((res) => {
          getProfileQueue(res);
          setQueue(res);
          setQueueLength(res.length);
          if (res.length === 0) {
            setPatientOnWaitList(false);
          } else {
            setPatientOnWaitList(true);
            setOpenPatientModal(true);
            sendDesktopNotification("Hay pacientes en la sala de espera...");
          }
        })
        .catch((err) => {
          if (process.env.REACT_APP_DEBUG === "true") {
            const errorMessage =
              "Error:  ~ file: VirtualGuard index.js:190 ~ getQueueInfo ~ err" +
              err;
            showToast(
              toastDispatch,
              errorMessage,
              ToastType.ERROR,
              ToastType.ERROR
            );
          }
        })
        .finally(() => setLoadingQueue(false));
    } else {
      const errorMessage =
        "Error en persistir la sesión, vuelva a iniciar sesión.";
      showToast(toastDispatch, errorMessage, ToastType.ERROR, ToastType.ERROR);
    }
  };

  const getQueueInfoOther = async () => {
    if (
      userData?.professional_dto?.specialties[0]?.id ||
      sessionProfileGet()?.userData.professional_dto?.specialties[0]?.id
    ) {
      setLoadingQueue(true);
      await getPatientsQueueOther(
        userToken,
        userData?.professional_dto?.specialties[0]?.id ||
          sessionProfileGet()?.userData.professional_dto?.specialties[0]?.id
      )
        .then((res) => {
          setQueueOther(res);
          getProfileQueueOther(res);
        })
        .catch((err) => {
          if (process.env.REACT_APP_DEBUG === "true") {
            const errorMessage =
              "Error:  ~ file: VirtualGuard index.js:190 ~ getQueueOther ~ err" +
              err;
            showToast(
              toastDispatch,
              errorMessage,
              ToastType.ERROR,
              ToastType.ERROR
            );
          }
        })
        .finally(() => setLoadingQueue(false));
    } else {
      const errorMessage =
        "Error en persistir la sesión, vuelva a iniciar sesión.";
      showToast(toastDispatch, errorMessage, ToastType.ERROR, ToastType.ERROR);
    }
  };

  // connect to ws
  const onConnectedProfessional = () => {
    if (userData?.professional_dto?.auth_id) {
      stompClient.subscribe(
        "/user/" + userData?.professional_dto?.auth_id + "/private",
        onPrivateMessageProfessional
      );
      stompClient.subscribe("/allusers/public", onAllMessage);
      dispatch({
        type: "VIRTUAL_GUARD",
        stomp: stompClient,
      });
    }
  };

  const onAllMessage = (payload) => {
    let payloadData = {};
    let nameSpecialties = "";
    if (payload?.body) {
      payloadData = JSON.parse(payload.body);
    } else {
      payloadData = payload;
    }
    if (userData?.professional_dto?.specialties[0]?.name) {
      if (
        payloadData?.specialtyId?.includes(
          "b3792b24-85a8-4373-8b06-c0d48ec744a4"
        )
      )
        nameSpecialties = "Clínica médica";
      if (
        payloadData?.specialtyId?.includes(
          "e09fa8d7-77f3-4c63-93e1-19e6caf1f6fc"
        )
      )
        nameSpecialties = "Pediatría";
      if (
        userData?.professional_dto?.specialties[0]?.name === nameSpecialties
      ) {
        if (
          payloadData.command === "SetOrder" ||
          payloadData.command === "PATIENT_QUEUE"
        ) {
          let patientQueue = [
            {
              id: payloadData.queueId,
              created_date: "",
              familymember: "",
              orderNumber: 0,
              patientid: payloadData?.patientId,
              professionalid: null,
              reason: "",
              specialtyId: "",
              status: "QUIT",
            },
          ];
          setEnableCancelProfessional(false);
          setCommand(patientData.command);
          getQueueInfo();
          //postProfessionalNotifications(userToken, userData, patientQueue, "PATIENT_QUEUE");
          setPatientOnWaitList(true);
          setOpenPatientModal(true);
          sendDesktopNotification("Hay pacientes en la sala de espera...");
        } else if (
          payloadData.command === "SetProfessional" ||
          payloadData.command === "FailSaveConsultation" ||
          payloadData.command === "ProfessionalFinish"
        ) {
          setOpenTimeout(true);
          setEnableCancelProfessional(true);
          getQueueInfo();
          getQueueInfoOther();
        } else if (
          payloadData.command === "PatientCancel" &&
          command !== patientData.command
        ) {
          let queueCancel = [
            {
              id: payloadData.queueId,
              created_date: "",
              familymember: "",
              orderNumber: 0,
              patientid: payloadData?.patientId,
              professionalid: null,
              reason: "",
              specialtyId: "",
              status: "QUIT",
            },
          ];
          localStorage.setItem("consult", null);
          //postProfessionalNotifications(userToken, userData, queueCancel, "CONSULTATION_CANCELLED");
          setEnableCancelProfessional(false);
          setCommand(patientData.command);
          setOnConsult(false);
          setDisabled(false);
          setPatientCancelled(true);
          setPatientFound(false);
          setOpenPatientModal(false);
          setOpenTimeout(false);
          getQueueInfo();
          getQueueInfoOther();
        }
      }
    }
  };

  // ws response function management
  const onPrivateMessageProfessional = async (payload) => {
    let payloadData = {};
    if (payload?.body) {
      payloadData = JSON.parse(payload.body);
    } else {
      payloadData = payload;
    }
    if (privatemessagews.get(payloadData.patientId)) {
      privatemessagews.get(payloadData.patientId).push(payloadData);
      setPrivatemessagews(new Map(privatemessagews));
    } else {
      let list = [];
      list.push(payloadData);
      privatemessagews.set(payloadData.patientId, list);
      setPrivatemessagews(new Map(privatemessagews));
    }
    // start call
    if (payloadData.command === "Start") {
      localStorage.setItem("consult", true);
      setOnConsult(false);
      setPatientData({
        ...patientData,
        patientId: payloadData.patientId,
        orderNumber: payloadData.orderNumber,
        professionalId: payloadData.professionalId,
        queueId: payloadData.queueId,
        reason: payloadData.reason,
        profesionalName: payloadData.profesionalName,
        specialtyId: patientData.specialtyId,
        connected: patientData.connected,
        profImage: payloadData.profImage,
        profSpecialty: payloadData.profSpecialty,
      });
      setVirtualGuardData({
        ...payloadData,
        uid: payloadData.professionalId,
        role: "publisher",
        channel: payloadData.queueId,
        layout: 1,
      });
      setVideoCall(true);

      // wait for patient to connect
    } else if (payloadData.command === "SetProfessional") {
      setPatientData({
        ...patientData,
        patientId: payloadData.patientId,
        orderNumber: payloadData.orderNumber,
        professionalId: payloadData.professionalId,
        queueId: payloadData.queueId,
        reason: payloadData.reason,
        specialtyId: patientData.specialtyId,
        profesionalName: payloadData.profesionalName,
        connected: patientData.connected,
        profImage: payloadData.profImage,
        profSpecialty: payloadData.profSpecialty,
      });
      localStorage.setItem("consult", true);
      setOnConsult(true);
      setPatientFound(true);
      setPatientOnWaitList(false);
      setLookingForPatient(false);
      setOpenTimeout(true);
      await EventLog(userToken, "enter call", "profesional", payloadData);
    } else if (
      payloadData.command === "PatientTimeoutCancel" ||
      payloadData.command === "ProfessionalTimeoutCancel"
    ) {
      localStorage.setItem("consult", null);
      setOpenTimeout(false);
      setOnConsult(false);
      setDisabled(false);
      setPatientFound(false);
      showToast(
        toastDispatch,
        "Se canceló la consulta por inactividad del paciente",
        ToastType.ERROR,
        ToastType.ERROR
      );
      await EventLog(
        userToken,
        "patient not connected",
        "profesional",
        payloadData
      );
      getQueueInfo();
      getQueueInfoOther();
    } else if (payloadData.status === "WAITING") {
      setPatientData({
        ...patientData,
        patientId: payloadData.patientId,
        orderNumber: payloadData.orderNumber,
        professionalId: payloadData.professionalId,
        queueId: payloadData.queueId,
        reason: payloadData.reason,
        specialtyId: patientData.specialtyId,
        profesionalName: payloadData.profesionalName,
        connected: patientData.connected,
        profImage: payloadData.profImage,
        profSpecialty: payloadData.profSpecialty,
      });
      setDisabled(true);
      setPatientFound(true);
      setPatientOnWaitList(false);
      setLookingForPatient(false);
    } else if (payloadData.command === "NoPatient") {
      setOpenTimeout(false);
      setEmptyQueue(true);
      getQueueInfo();
      setDisabled(false);
      setPatientOnWaitList(false);
      setPatientFound(false);
    } else if (payloadData.command === "PatientCancel") {
      localStorage.setItem("consult", null);
      setOpenTimeout(false);
      setOnConsult(false);
      setDisabled(false);
      setPatientCancelled(true);
      setPatientFound(false);
      getQueueInfo();
      getQueueInfoOther();
      await EventLog(
        userToken,
        "patient cancelled",
        "profesional",
        payloadData
      );
    }
    dispatch({
      type: "VIRTUAL_GUARD",
      data: payloadData,
      stomp: stompClient,
    });
  };

  // ws error
  const onError = (err) => {
    showToast(toastDispatch, err, ToastType.ERROR, ToastType.ERROR);
  };

  // get latest patient
  const getPatient = (patient) => {
    if (hasPermission) {
      playAudio(patientOnQueueSound);
      var wsMessage = {
        orderNumber: patient?.order_number,
        specialtyId: userData?.professional_dto?.specialties[0].id,
        command: "GetPatient",
        reason: patient?.reason,
        professionalId: userData?.professional_dto?.auth_id,
        profesionalName: doctorName,
        profImage: patient?.profImage,
        profSpecialty: patient?.profSpecialty,
        queueId: patient?.queueId,
        patientId: patient?.patientid ? patient?.patientid : patient?.patientId,
      };
      if (disabled === false) {
        firebase.put({
          ...wsMessage,
          command: "SetProfessional",
          status: "STARTED",
        });
        stompClient.send("/app/message", {}, JSON.stringify(wsMessage));
        setLookingForPatient(true);
        setDisabled(true);
      }
    } else {
      setAskPermission(true);
    }
  };

  const cancellConsult = () => {
    var wsMessage = {
      patientId: patientData.patientId,
      orderNumber: patientData.orderNumber,
      specialtyId: patientData.specialtyId,
      command: "ProfessionalCancel",
      professionalId: patientData.professionalId,
      queueId: patientData.queueId,
      date: patientData.date,
    };
    stompClient.send("/app/message", {}, JSON.stringify(wsMessage));
    localStorage.setItem("consult", null);
    setOnConsult(false);
    setPatientCancelled(false);
    setPatientFound(false);
    setDisabled(false);
    setLookingForPatient(false);
  };

  const patientQueueOtherResult = (disabledHead = undefined) => {
    return (
      patientQueueOther.length !== 0 && (
        <CustomizedTables
          disableElements={true}
          disabledRows={true}
          disabledHead={disabledHead}
          data={patientQueueOther}
          buttonCall={<CallOutlined />}
          buttonConsultation={
            REACT_APP_SEND_CONSULTATION ? <Description /> : null
          }
          setConsultation={REACT_APP_SEND_CONSULTATION ? setConsultation : null}
          setSend={REACT_APP_SEND_PATIENT ? setSend : null}
          buttonSend={REACT_APP_SEND_PATIENT ? <Send /> : null}
        />
      )
    );
  };

  const patientQueueResult = () => {
    return (
      patientQueue.length !== 0 && (
        <div>
          <CustomizedTables
            setCall={setCall}
            call={call}
            data={patientQueue}
            buttonCall={<CallOutlined></CallOutlined>}
          />
          {patientQueueOtherResult(true)}
        </div>
      )
    );
  };

  // message to professional for each ws response
  const Result = () => {
    if (patientOnWaitList && !patientFound) {
      return (
        <div>
          {patientQueue.length === 0 && emptyDrid()}
          <p>
            Hay pacientes en la sala de espera, haz click en{" "}
            <b>el botón de llamada</b> para comenzar la consulta.
          </p>
          {patientQueue.length === 0 && <LoadingSpinner />}
        </div>
      );
    } else if (patientFound) {
      return (
        <div>
          <p>Paciente encontrado, esperando su respuesta...</p>
          <LoadingSpinner />
        </div>
      );
    } else if (emptyQueue && !patientOnWaitList && queueLength === 0) {
      return (
        <div className="virtual-guard">
          {emptyDrid()}
          <p>No hay pacientes en la cola, esperando pacientes...</p>
          <LoadingSpinner />
        </div>
      );
    } else if (lookingForPatient) {
      return (
        <div className="virtual-guard">
          {patientQueue.length === 0 ? emptyDrid() : null}
          <p>
            Espere un momento mientras se realiza la conexión con su paciente...
          </p>
          <LoadingSpinner />
        </div>
      );
    } else {
      return (
        <div className="virtual-guard">
          {patientQueue.length === 0 ? emptyDrid() : null}
          <p>Sin pacientes en grilla. Esperando ingreso de pacientes...</p>
          <LoadingSpinner />
        </div>
      );
    }
  };

  const emptyDrid = () => {
    return patientQueueOtherResult() ? (
      patientQueueOtherResult()
    ) : (
      <CustomizedTables
        setCall={null}
        call={null}
        data={[
          {
            "Prof.": "-",
            Nombre: "-",
            Correo: "-",
            Financiador: "-",
            "Tiempo de Espera/Hora": "-",
            Estado: "-",
          },
        ]}
        buttonCall={null}
      />
    );
  };

  useEffect(() => {
    if (queueLength !== 0 && call !== null) {
      let respondQueue = queue.filter(
        (element) =>
          processEmail(element.familymember) === call.Correo &&
          element?.first_name + " " + element?.last_name === call.Nombre
      );
      getPatient(respondQueue[0]);
      setCall(null);
    }
  }, [call]);

  useEffect(() => {
    if (patientQueueOther.length !== 0 && send !== null) {
      let respondQueue = queueOther.filter(
        (element) =>
          processEmail(element.familymember) === send.Correo &&
          element?.first_name + " " + element?.last_name === send.Nombre
      );
      setPatientSend(respondQueue[0]);
      setOpenChatSend(true);
      setSend(null);
    }
  }, [send]);

  useEffect(() => {
    if (patientQueueOther.length !== 0 && consultation !== null) {
      let respondQueue = queueOther.filter((element) => processEmail(element.familymember) === consultation.Correo && (element?.first_name + ' ' + element?.last_name) === consultation.Nombre && (formatDateHourAndMinute(element.created_date) === consultation["Tiempo de Espera/Hora"]));
      setPatientConsultation(respondQueue[0]);
      setOpenConsultation(true);
      setConsultation(null);
    }
  }, [consultation]);

  const consultationMemo = () =>
    useMemo(() => {
      return (
        <Consultation
          getQueueAll={getQueueAll}
          stomp={stompClient}
          consultationData={patientConsultation}
          open={openConsultation}
          setOpen={setOpenConsultation}
        ></Consultation>
      );
    }, [patientConsultation, openConsultation]);

  const chatOverlaySendMemo = () =>
    useMemo(() => {
      return (
        <ChatOverlaySend
          setOpenChatSend={setOpenChatSend}
          openChatSend={openChatSend}
          setPatientSend={setPatientSend}
          patientSend={patientSend}
        ></ChatOverlaySend>
      );
    }, [patientSend, openChatSend]);

  window.onbeforeunload = () => {
    // removeLocalStorageItem(POST_VIDEOCALL_KEY)
    localStorage.setItem("consult", null);
  };

  return (
    <div className="virtual-guard">
      {patientQueueResult()}
      <div className="header">
        <img src={HeaderImage} alt="Guardia Virtual" />
        <h2>
          Guardia Virtual - {userData?.professional_dto?.specialties[0]?.name}
        </h2>
        <Tooltip
          slots={{
            transition: Zoom,
          }}
          title="Recargar Grilla"
        >
          <IconButton
            onClick={() => {
              realoadComponent();
            }}
          >
            <Cached></Cached>
          </IconButton>
        </Tooltip>
      </div>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Result />
      </div>
      {consultationMemo()}
      {chatOverlaySendMemo()}
      <AllowIncomingCallSound
        setIsAllowed={setIsAllowed}
        isAllowed={isAllowed}
      />
      {isAllowed && (
        <Modal
          isActive={openPatientModal}
          handleClose={() => setOpenPatientModal(false)}
        >
          <div className={MedilineClasses.btnBoxCenterM0}>
            <div className={MedilineClasses.titleBlue}>
              Hay pacientes en la sala de espera
            </div>
            <div className={MedilineClasses.btnSpacing}>
              <div style={{ margin: "15px" }}>
                <Button
                  className={MedilineClasses.btnBlueNext}
                  onClick={() => setOpenPatientModal(false)}
                >
                  Aceptar
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default VirtualGuard;
