import ConsultationWrapper  from '@mediline/mediline-api-middleware/src/wrappers/consultation.wrapper'

import HttpClient from './fetch_api';
import { CONSULTATION, INDICATIONS_TEMPLATE } from '../conf/appSetting';

const cw = new ConsultationWrapper();

//export const ConsultationCreate = async (consultation, token) => cw.createConsultation(token, consultation);

export const ConsultationCreate = async (consultation, token) => cw.createAgoraConsultation(token, consultation);

export const ConsultationAgoraUpdate = async (consultation, token, id)  => cw.updateAgoraConsultation(token, consultation, id);

export const PostRanking = async (token, queueId, star) => cw.getRanking(token, queueId, star);

export const ConsultationGet = async (id, token) => cw.getConsultationById(token, id);

export const ConsultationUpdate = async (data, token) => cw.updateConsultation(token, data);

export const ConsultationPatientGet = async (token, params) => cw.getConsultationPatient(token, params);

export const getConsultationByAppointment = async (gatewayToken, params) =>
   HttpClient.get(HttpClient.concatNotNullParam(CONSULTATION.base, params), {
      Authorization: `Bearer ${gatewayToken}`,
   });
export const getIndicationsTemplate = async (gatewayToken) =>{
   
   let indications = await HttpClient.get(INDICATIONS_TEMPLATE.base, {
      Authorization: `Bearer ${gatewayToken}`,
   });

    return indications && indications.length && indications.map(indication => ({
        value: indication.id, label: indication.name, name: indication.name, id: indication.id
    }))}