import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
   Accordion,
   AccordionActions,
   AccordionDetails,
   AccordionSummary,
   Button,
} from '@material-ui/core';

import ConsultRegistry from '../../ConsultRegistry';
import Certificate from '../../Certificate';
import Prescription from '../../Prescription';
import Derivation from '../../Derivation';
import Studies from '../../Studies';
import Patient from '../../Patient';
import OkIcon from '../../../../../../assets/img/ok.svg';
import CertificateIcon from '../../../../../../assets/img/certificado.svg';
import ConsultationIcon from '../../../../../../assets/img/consulta.svg';
import DerivationIcon from '../../../../../../assets/img/derivar.svg';
import StudiesIcon from '../../../../../../assets/img/estudios.svg';
import PrescriptionIcon from '../../../../../../assets/img/prescripcion.svg';
import indicationTemplate from '../../../../../../assets/img/indications-template.svg';
import IndicationTemplates from '../../IndicationTemplate';

const ImageAcordion = {
   Approve: OkIcon,
   C: CertificateIcon,
   D: DerivationIcon,
   PE: StudiesIcon,
   PC: PrescriptionIcon,
   IT: indicationTemplate,
   RC: ConsultationIcon,
};

const AccordionCustom = ({
   expanded,
   hasErrors,
   errors,
   saveOnStorage,
   values,
   setFieldValue,
   formField,
   handleChange,
   required,
   title,
   setDisabledSubmit,
   consultation,
   patient,
   type,
   disable=false
}) => {
   const isName = type === 'Name';
   const [disabled, setDisabled] = useState();
   const [confirmed, setConfirmed] = useState();

   useEffect(() => {
      if (['RC', 'PE', 'D', 'C', 'PC','IT'].includes(type)) {
         disabled ? setConfirmed(false) : setConfirmed(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [disabled]);

   const handleDisabled = (err) => {
      // The arg "err" is the "hasErrors" boolean of Certificate, Derivation, Studies & ConsultRegistry
      // which has an useEffect listening for changes of each sub-component
      setDisabled(err);
      if (type === 'RC') {
         setDisabledSubmit(err);
      }
   };

   const handleClick = () => {
      handleChange();
      // save form values to local storage to avoid losing form data with f5
      if (saveOnStorage) {
         saveOnStorage();
      }
   };

   const fillerDictionary = {
      Name: <Patient patient={patient}></Patient>,
      C: (
         <Certificate
            formField={formField}
            hasErrors={!!hasErrors}
            handleDisabled={handleDisabled}
            consultation={consultation}
         ></Certificate>
      ),
      D: <Derivation
            formField={formField}
            setFieldValue={setFieldValue}
            parentValues={values}
            handleDisabled={handleDisabled}
            hasErrors={!!hasErrors}
            errors={errors}
          ></Derivation>,
      PE: (
         <Studies
            formField={formField}
            setFieldValue={setFieldValue}
            parentValues={values}
            handleDisabled={handleDisabled}
            hasErrors={!!hasErrors}
            errors={errors}
         ></Studies>
      ),
      PC: (
         <Prescription
            formField={formField}
            setFieldValue={setFieldValue}
            parentValues={values}
            handleDisabled={handleDisabled}
            hasErrors={!!hasErrors}
            errors={errors}
         ></Prescription>
      ),
      IT: (
         <IndicationTemplates formField={formField}
            setFieldValue={setFieldValue}
            parentValues={values}
            handleDisabled={handleDisabled}
            hasErrors={!!hasErrors}
            errors={errors}></IndicationTemplates>
      ),
      RC: (
         <ConsultRegistry
            disabled={disable}
            formField={formField}
            handleDisabled={handleDisabled}
            hasErrors={!!hasErrors}
            consultation={consultation}
         ></ConsultRegistry>
      ),
   };

   return (
      <Accordion
         expanded={expanded === type}
         onChange={handleChange}
         className="accordion"
      >
         <AccordionSummary
            expandIcon={<ExpandMoreIcon className="icon" />}
            className={`summary ${isName && 'name'} ${
               !isName && confirmed && 'confirmed'
            }`}
         >
            <div className="header">
               {!isName && (
                  <img
                     className="icon"
                     alt={type}
                     src={
                        confirmed
                           ? ImageAcordion['Approve']
                           : ImageAcordion[type]
                     }
                  />
               )}
               <h3 className={`title ${isName && 'name'}`}>
                  {title} {required && <span className="required">*</span>}
               </h3>
            </div>
         </AccordionSummary>
         <AccordionDetails>{fillerDictionary[type]}</AccordionDetails>
         <AccordionActions className="btn-wrapper">
            {!isName && (
               <Button
                  disabled={disabled}
                  className={`btn ${disabled && 'disabled'}`}
                  onClick={handleClick}
               >
                  Confirmar
               </Button>
            )}
         </AccordionActions>
      </Accordion>
   );
};
export default AccordionCustom;
