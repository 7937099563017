import React, { useState, useEffect, useRef } from "react";
import Modal from "../components/Modal";
import { Button } from "@material-ui/core";
import { MediaPermissionsErrorType, requestMediaPermissions } from "mic-check";
import { ApplicationStyle } from "../theme";
import { makeStyles } from "@material-ui/core/styles";

const MedilineStyle = makeStyles(ApplicationStyle);

export const AllowIncomingCallSound = ({ setIsAllowed, isAllowed }) => {
  const [error, setError] = useState();
  const [open, setOpen] = useState(false);
  const MedilineClasses = MedilineStyle();
  const isMountedRef = useRef(true);

  useEffect(() => {
    if (isMountedRef.current && isAllowed != true) {
      AskPermission();
    }
    return () => (isMountedRef.current = false);
  }, [isMountedRef.current]);
  const AskPermission = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
    setIsAllowed(true);
  };

  return (
    <div style={{zIndex:1000}}>
      <Modal isActive={open} handleClose={handleClose}>
        <div className={MedilineClasses.btnBoxCenterM0}>
          <div className={MedilineClasses.titleBlue}>
            Permisos de sonido de llamada entrante.
          </div>
          <div className={MedilineClasses.subtitleBlue}>
            Mediline sonara una alerta de llamada entrante para poder notificar
            al profesional, permitir el uso de la alerta.
          </div>
          <Button
            className={MedilineClasses.btnBlueNext}
            onClick={() => handleClose(true)}
          >
            Permitir
          </Button>
        </div>
      </Modal>
    </div>
  );
};
