import React, {useEffect, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {Button} from "@material-ui/core";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

import HistoryClinicIcon from "../../../assets/img/historial-prestaciones.svg";
import {ConsultationPatientGet} from "../../../services/consultationService";
import {PatientGet} from "../../../services/patientService";
import {showToast, ToastType} from "../../../utils/ToastUtil";
import {useStateAuthValue} from "../../../context/Auth/AuthState";
import {useToast} from "../../../context/Toast/ToastProvider";
import PersonalData from "./personalData";
import ConsultationData from "./consultationData";
import LoadingSpinner from "../../../components/LoadingSpinner";

const HistoryClinicDialog = ({email}) => {
  const [userData] = useStateAuthValue();
  const history = useHistory();
  const toastDispatch = useToast();
  const [dataHC, setDataHC] = useState();
  const [dataPatient, setDataPatient] = useState();
  const [page, setPage] = useState(0);
  const [loadingHC, setLoadingHC] = useState(false);
  const [loadingPatient, setLoadingPatient] = useState(false);
  const [isNextPageDisabled, setIsNextPageDisabled] = useState(false);

  //TODO se realiza de esta manera porque desde el back no me devuelve limite, se pone 100000 por defecto
  const [limit, setLimit] = useState(100000);
  
  const errorMessagePatient = "Ocurrió un error en la búsqueda del paciente";
  const errorMessageConsultation = "Ocurrió un error en la búsqueda de la consulta";

  useEffect(() => {
    if (userData?.userToken) {
      setLoadingPatient(true);
      getDataPatient();
    }
  }, [email, userData?.userToken]);

  useEffect(() => {
    if (userData?.userToken) {
      setLoadingHC(true);
      if (page < limit - 1) {
        setIsNextPageDisabled(false);
      }
      getDataHC();
    }
  }, [userData?.userToken, page]);

  const getDataHC = async () => {
    const previusData = dataHC;

    await ConsultationPatientGet(
      userData?.userToken,
      { patientLogin: encodeURIComponent(email) , page: page, size:1,sort:'createdDate,desc' },
      (sessionError) => showError(sessionError)
    )
      .then((response) => {
        if ((response?.data === null || response?.data === '') && page !== 0) {
          setDataHC(previusData);
          setIsNextPageDisabled(true);
          setLimit(page);
          setPage(page - 1);
          
        } else {
          setDataHC(response?.data);
        }
      })
      .catch(() => {
        showError(errorMessageConsultation);
      })
      .finally(() => setLoadingHC(false));
  };

  const showError = (msgError) => {
    showToast(toastDispatch, msgError, ToastType.ERROR, ToastType.ERROR);
  };

  const getDataPatient = async () => {
    await PatientGet(email, userData.userToken, (sessionError) =>
      showError(sessionError)
    )
      .then((response) => {
        setDataPatient(response?.data);
      })
      .catch(() => {
        showError(errorMessagePatient);
      })
      .finally(() => setLoadingPatient(false));
  };


  const consultation = () => {
    return loadingHC ? (
      <LoadingSpinner />
    ) : (
      <div className="column">
        <ConsultationData data={dataHC} />
      </div>
    );
  };

  return (
    <div className="history-clinic-detail">
      <div className="header-hc">
        <img className="image" src={HistoryClinicIcon} alt="Historia Clinica" />
        <h2>
          Historia Clínica N° {dataPatient?.patient_dto?.clinicHistoryId} - {dataPatient?.last_name},{" "}
          {dataPatient?.first_name}
        </h2>
      </div>
      {loadingPatient ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="column">
            <PersonalData data={dataPatient} />
          </div>
          <div className={`btn-wrapper`}>
            Consulta N° {dataHC?.consultationId}
            <Button
                className={`btn btn--back ${isNextPageDisabled && "btn--disabled"}`}
                endIcon={<DoubleArrowIcon className="icon-back" size={24}/>}
                onClick={() => !isNextPageDisabled && setPage(page + 1)}
            >
              Consulta anterior
            </Button>
            <Button
                className={`btn btn--next ${page < 1 && "btn--disabled"}`}
                startIcon={<DoubleArrowIcon size={24}/>}
                onClick={() => page > 0 && setPage(page - 1)}
            >
              Consulta siguiente
            </Button>
          </div>
          {consultation()}
        </>
      )}
    </div>
  );
};

export default HistoryClinicDialog;

