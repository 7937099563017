import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { KeyboardArrowDown } from '@material-ui/icons';
import { KeyboardArrowUp } from '@material-ui/icons';
import { Tooltip, Zoom } from "@material-ui/core";

const styles = (theme) => ({
  tableContainer: {
    borderRadius: '15px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    minWidth: '1000px'
  },
  head: {
    backgroundColor: '#002C54',
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
  },
  odd: {
    backgroundColor: '#f9f9f9',
  },
  disabled: {
    color: 'gray',
  },
});

function CustomizedTables(props) {
  const { classes, data, buttonCall, setCall, analyzeParam, disabledHead, disableElements, disabledRows, showRows, setSend, buttonSend, setConsultation, buttonConsultation } = props;
  const [expandedGroups, setExpandedGroups] = useState({});

  if (data === null || !Array.isArray(data) || data.length === 0) {
    return null;
  }

  const groupData = (data, params) => {
    if (!params || !Array.isArray(params) || params.length === 0) return data;

    const groupedData = {};
    data.forEach((item) => {
      if (item[showRows]) item[showRows] = '-';
      const key = params.map(param => item[param]).join('-');
      if (groupedData[key]) {
        groupedData[key].Cantidad += 1;
        groupedData[key].items.push(item);
      } else {
        groupedData[key] = { ...item, Cantidad: 1, items: [item] };
      }
    });
    return Object.values(groupedData);
  };

  const groupedData = analyzeParam ? groupData(data, analyzeParam) : data;

  const handleExpandClick = (key) => {
    setExpandedGroups(prev => ({ ...prev, [key]: !prev[key] }));
  };

  const renderTableHead = () => (
    <TableHead>
      <TableRow>
        <TableCell className={classes.head}>
          {''}
        </TableCell>
        {Object.keys(groupedData[0]).map((key) => (
          key !== 'items' && (
            <TableCell key={key} className={classes.head}>
              {key}
            </TableCell>
          )
        ))}
        <TableCell className={classes.head}>
          {'Acción'}
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const renderTableBody = () => (
    <TableBody>
      {groupedData.map((group, index) => {
        const groupKey = analyzeParam ? analyzeParam.map(param => group[param]).join('-') : index;
        return (
          <React.Fragment key={index}>
            <TableRow className={index % 2 === 0 ? classes.odd : ''}>
              <TableCell>
                {analyzeParam && (
                  <Button
                    variant="contained"
                    onClick={() => handleExpandClick(groupKey)}
                  >
                    {expandedGroups[groupKey] ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                  </Button>
                )}
              </TableCell>
              {Object.entries(group).map(([key, value], colIndex) => {
                if (key === 'items') return null;
                return (
                  <TableCell 
                    key={colIndex} 
                    align="left" 
                    className={`${classes.body} ${disabledRows ? classes.disabled : ''}`}
                  >
                    {key === 'Cantidad' ? `${value}` : value}
                  </TableCell>
                );
              })}
              <TableCell>
                <div style={{display: 'flex'}}>
                {buttonCall && (
                  <Button
                    className="virtual-guard-button-call-response"
                    variant="contained"
                    endIcon={buttonCall ? buttonCall : null}
                    onClick={() => setCall(group)}
                    disabled={disableElements}
                  >
                  </Button>
                )}
                {!buttonCall && (
                  <TableCell>-</TableCell>
                )}
                {buttonConsultation && (
                  <Button
                    className="virtual-guard-button-consultation-response"
                    variant="contained"
                    endIcon={buttonConsultation ? buttonConsultation : null}
                    onClick={() => setConsultation(group)}
                  >
                  </Button>
                )}
                {buttonSend && (
                  <Button
                    className="virtual-guard-button-send-response"
                    variant="contained"
                    endIcon={buttonSend ? buttonSend : null}
                    onClick={() => setSend(group)}
                  >
                  </Button>
                )}
                </div>
              </TableCell>
            </TableRow>
            {expandedGroups[groupKey] && group.items && group.items.map((item, idx) => (
              <TableRow key={`${groupKey}-${idx}`} className={index % 2 === 0 ? classes.odd : ''}>
                <TableCell>
                </TableCell>
                {Object.entries(item).map(([key, value], colIndex) => (
                  <TableCell 
                    key={colIndex} 
                    align="left" 
                    className={`${classes.body} ${disabledRows ? classes.disabled : ''}`}
                  >
                    {value}
                  </TableCell>
                ))}
                <TableCell>
                </TableCell>
              </TableRow>
            ))}
          </React.Fragment>
        );
      })}
    </TableBody>
  );

  return (
    <div style={{ maxHeight: '400px', minWidth: '1000px', overflowX: 'auto' }}> {
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="customized table">
          {disabledHead === undefined && renderTableHead()}
          {renderTableBody()}
        </Table>
      </TableContainer>
    }
    </div>
  );
}

export default withStyles(styles)(CustomizedTables);
