import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import AccordionCustom from '../AccordionList/AccordionCustom';
import ConsultationFormModel from '../PostVideoCallForm/ConsultationFormModel';
import InitialValues from '../PostVideoCallForm/InitialValues';
import ValidationSchema from '../PostVideoCallForm/ValidationSchema';
import { Button, CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import TechnicalDifficultiesModal from '../../../../../components/TechnicalDifficultiesModal';
import ConfirmationModal from '../../../../../components/ConfirmationModal/ConfirmationModal';
import { ConsultationCreate, ConsultationAgoraUpdate, ConsultationGet } from '../../../../../services/consultationService';
import { useStateAuthValue } from '../../../../../context/Auth/AuthState';
import { showToast, ToastType } from '../../../../../utils/ToastUtil';
import { useToast } from '../../../../../context/Toast/ToastProvider';
import { MeetingStatusEnum } from '../../../../../models/enums/MeetingStatusEnum';
import {
   POST_VIDEOCALL_KEY,
   get,
   set,
   removeLocalStorageItem,
} from '../../../../../services/localStorageService';
import { PatientGet } from '../../../../../services/patientService';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import CloseIcon from "@material-ui/icons/Close";
import firebase from '../../../../../services/firebase';
import LinearProgress from '@material-ui/core/LinearProgress';

const Consultation = ({ consultationData, open, setOpen, stomp, getQueueAll }) => {
   const { formId, formField } = ConsultationFormModel;
   const [initialValues, setInitialValues] = useState({ ...InitialValues });
   const [expanded, setExpanded] = useState(false);
   const [disabledSubmit, setDisabledSubmit] = useState(true);
   const [isSubmitting, setIsSubmitting] = useState(false);
   const [{ userToken, userData }] = useStateAuthValue();
   const [patient, setPatient] = useState({
      first_name: "",
      last_name: ""
   });
   const [loadingConsultation, setLoadingConsultation] = useState(false);
   const toastDispatch = useToast();
   const handleClose = () => setOpen(false);
   let request = { ...consultationData }

   const showError = (msgError) => {
      showToast(toastDispatch, msgError, ToastType.ERROR, 'Error');
   };

   useEffect(() => {
      let isMounted = true;
      setInitialValues({ ...InitialValues });
      setLoadingConsultation(false);
      setIsSubmitting(false);

      if (isMounted && userToken && consultationData?.familymember) {
         getData();
      }

      if (isMounted && userToken && consultationData?.consultation_id) {
         getConsultation();
      }

      return () => {
         isMounted = false;
      };
   }, [consultationData, userToken]);

   const getData = async () => {
      setLoadingConsultation(true);
      await PatientGet(consultationData?.familymember, userToken, showError)
         .then((response) => {
            setPatient({ ...response?.data, familymember: consultationData?.familymember });
         }).catch(() => showError('Ocurrió un error al obtener los datos del paciente'))
         .finally(() => setLoadingConsultation(false));
   };

   const getConsultation = async () => {
      await ConsultationGet(consultationData?.consultation_id, userToken, showError)
         .then((response) => {
            let newInitialValues = {}
            newInitialValues = {
               diagnosis: response?.data?.consultation?.diagnosis || '',
               Diagnosis: response?.data?.consultation?.diagnosis || '',
               reason: response?.data?.consultation?.reason || '',
               detail: response?.data?.consultation?.detail || '',
               medications: response?.data?.prescriptions?.flatMap(prescription =>
                  prescription.medications.map(medication => ({
                    ...medication,
                    primary: medication.diagnosis,
                    disabled: true,
                  }))
                ) || [],
                other_indications: response?.data?.indications
                ?.filter(indication => indication.name === "Otra indicacion")
                ?.map(indication => ({
                  ...indication,
                  disabled: true,
                })) || [],
               certificates: response?.data?.certificates?.[0] || null,
               specialties: {specialtyNames: response?.data?.specialties.map(specialty => ({
                  field: specialty?.comment || '',
                  id: specialty?.id || 0,
                  name: specialty?.name || '',
                  primary: specialty?.name || '',
                  disabled: true,
               })) || []},
               studies: {studyNames: response?.data?.studies.map(studie => ({
                  id: studie?.id || 0,
                  name: studie?.name || '',
                  primary: studie?.name || '',
                  disabled: true,
               })) || []},
               indicationsTemplateDTOList: response?.data?.indicationsTemplateConsultations?.map(indication => ({
                  ...indication,
                  id: indication.indication_template_id,
                  disabled: true,
                })) || []
            }
            setInitialValues({...newInitialValues})
         }).catch(() => showError('Ocurrió un error al obtener los datos de la consulta'));
   };

   useEffect(() => {
      let isMounted = true;
      const savedValues = get(POST_VIDEOCALL_KEY);
      if (isMounted && savedValues) {
         setInitialValues(savedValues);
         setDisabledSubmit(
            !savedValues.detail?.length || !savedValues.diagnosis?.length,
         );
      }
      return () => {
         isMounted = false;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const confirmConsult = async (requestConsultation) => {
      setIsSubmitting(true);
      await (consultationData?.consultation_id ? ConsultationAgoraUpdate(requestConsultation, userToken, consultationData?.consultation_id) : ConsultationCreate(requestConsultation, userToken))
         .then((element) => {
            removeLocalStorageItem(POST_VIDEOCALL_KEY)
            if (Array.isArray(element) && element.length === 0) {
               setTimeout(() => { failSaveConsultation(); }, 2000)
               showToast(toastDispatch, 'No se guardo los datos de las consulta correctamente', ToastType.ERROR);
            } else {
               professionalFinishConsultation();
               showToast(toastDispatch, 'Se registró la consulta correctamente', ToastType.SUCCESS);
            }
         })
         .catch(() => {
            showError('Ocurrió un error al obtener la consulta');
         }).finally(() => {
            getQueueAll();
            setIsSubmitting(false);
         });

   };


   const failSaveConsultation = () => {
      var wsMessage = {
         patientId: consultationData.patientid,
         professionalId: consultationData.professionalid,
         orderNumber: consultationData.order_number,
         command: "FailSaveConsultation",
         specialtyId: consultationData.specialty_id,
         queueId: consultationData.id,
         date: consultationData.created_date,
         status: "ERROR",
      };
      firebase.put({ ...wsMessage })
      stomp.send("/app/message", {}, JSON.stringify(wsMessage));
      removeLocalStorageItem(POST_VIDEOCALL_KEY)
   }

   const professionalFinishConsultation = () => {
      var wsMessage = {
         patientId: consultationData.patientid,
         professionalId: consultationData.professionalid,
         orderNumber: consultationData.order_number,
         command: "ProfessionalFinish",
         specialtyId: consultationData.specialty_id,
         queueId: consultationData.id,
         date: consultationData.created_date,
         status: "FINISHED",
      };
      firebase.put({ ...wsMessage })
      stomp.send("/app/message", {}, JSON.stringify(wsMessage));
      removeLocalStorageItem(POST_VIDEOCALL_KEY)
   }

   const handleSubmit = (values, errors) => {
      request = { ...request, ...values };
      request.status = MeetingStatusEnum.FINISHED;

      //this convertions is necesary to not delay input in formik
      //add Diagnosis because are many field call diagnosis and delay input fields 
      request.diagnosis = values.Diagnosis;
      delete request.Diagnosis;

      if (!values.reason && consultationData.reason) {
         request.reason = consultationData.reason;
      }

      if (consultationData.queue_id) {
         request.queueId = consultationData.queue_id;
      }

      // Prepares post studies
      request.studies = [];
      if (values?.studies?.studyNames?.length && values?.studies?.diagnosis) {
         values.studies.studyNames.forEach(studyItem => request.studies.push({ name: studyItem.name, diagnosis: values.studies.diagnosis }))
      }

      // Prepares post certificates      
      request.certificates = values?.certificates && !errors?.certificates ? [values.certificates] : [];

      request.specialties = [];
      if (values?.specialties?.specialtyNames?.length && values?.specialties?.diagnosis) {
         values.specialties.specialtyNames.forEach(specialty => request.specialties.push({ name: specialty.name, diagnosis: values.specialties.diagnosis, comment: specialty.field }))
      }

      if (request?.medications?.length) {
         request.medications = request.medications.map(medication => {
            const newMedication = { ...medication };
            delete newMedication.id;
            delete newMedication.primary;
            return newMedication;
         })
      }
      request.patient_dto = {
         affiliate_number: patient.patient_dto.affiliateNumber,
         prepaid_health_category: patient.patient_dto.prepaid_health_category,
         attention_type: "VIRTUAL_GUARD"
      },
         request.professional_dto = {
            drName: userData.first_name + " " + userData.last_name,
            professionalId: userData.professional_dto.id,
            professional_name: userData.first_name + " " + userData.last_name,
            professional_license: userData.professional_dto?.licence_str,
            professional_specialties: userData.professional_dto?.specialties,
            email: userData.email,
         }
      confirmConsult(request);
      setOpen(false);
   };

   const handleChange = (panel) => (e, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
   };

   const styleBoxModal = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 1000,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
   };

   const styleCloseModal = {
      position: 'absolute',
      top: 0,
      right: 0,
   };

   return (
      <>
         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={styleBoxModal}>
               <div style={styleCloseModal} className="close">
                  <Tooltip title="Cerrar">
                     <IconButton onClick={() => handleClose(false)}>
                        <CloseIcon />
                     </IconButton>
                  </Tooltip>
               </div>
               <div className="videocall" style={{ display: "flex" }} >
                  <div className="container">
                     <div style={{ height: "80vh", width: "140%" }}>
                        <div className="action-panel" style={{ height: "100%" }}>
                           {loadingConsultation ? <LinearProgress/> : null}
                           <Formik
                              initialValues={initialValues}
                              validationSchema={ValidationSchema}
                              onSubmit={(values, errors) => handleSubmit(values, errors)}
                              enableReinitialize
                              validateOnMount
                           >
                              {({ errors, touched, values, setFieldValue }) => {
                                 return (
                                    <Form id={formId}>
                                       <div className="accordion-list">
                                          <div className="accordion-wrapper">
                                             <AccordionCustom
                                                title={`Paciente ${patient?.first_name || ''} ${patient?.last_name || ''
                                                   }`}
                                                type="Name"
                                                expanded={expanded}
                                                handleChange={handleChange('Name')}
                                                patient={patient}
                                             ></AccordionCustom>
                                             <AccordionCustom
                                                required
                                                title="Registro de Consulta"
                                                type="RC"
                                                expanded={expanded}
                                                handleChange={handleChange('RC')}
                                                formField={formField}
                                                hasErrors={
                                                   !values.detail ||
                                                   !values.Diagnosis ||
                                                   (!values.reason && !consultationData.reason) ||
                                                   errors?.detail ||
                                                   errors?.Diagnosis ||
                                                   (errors?.reason && !consultationData.reason)
                                                }
                                                saveOnStorage={() => saveOnStorage(values)}
                                                setDisabledSubmit={setDisabledSubmit}
                                                consultation={consultationData}
                                                disable={true}
                                             ></AccordionCustom>
                                             <AccordionCustom
                                                title="Prescripción"
                                                type="PC"
                                                expanded={expanded}
                                                handleChange={handleChange('PC')}
                                                formField={formField}
                                                setFieldValue={setFieldValue}
                                                saveOnStorage={() => saveOnStorage(values)}
                                                values={values}
                                                errors={errors?.medications}
                                                hasErrors={
                                                   !values?.medications?.length ||
                                                   (touched?.medications && errors?.medications)
                                                }
                                             ></AccordionCustom>
                                             <AccordionCustom
                                                title="Plantilla Indicaciones"
                                                type="IT"
                                                expanded={expanded}
                                                handleChange={handleChange('IT')}
                                                formField={formField}
                                                setFieldValue={setFieldValue}
                                                saveOnStorage={() => saveOnStorage(values)}
                                                values={values}
                                                errors={errors?.indicationsTemplateDTOList}
                                                hasErrors={
                                                   !values?.indicationsTemplateDTOList?.length ||
                                                   (touched?.indicationsTemplateDTOList && errors?.indicationsTemplateDTOList)
                                                }
                                             ></AccordionCustom>
                                             <AccordionCustom
                                                title="Certificado Médico"
                                                type="C"
                                                expanded={expanded}
                                                handleChange={handleChange('C')}
                                                formField={formField}
                                                hasErrors={
                                                   !values?.certificates || errors?.certificates
                                                }
                                                saveOnStorage={() => saveOnStorage(values)}
                                                consultation={consultationData}
                                             ></AccordionCustom>
                                             <AccordionCustom
                                                title="Derivación"
                                                type="D"
                                                expanded={expanded}
                                                handleChange={handleChange('D')}
                                                formField={formField}
                                                values={values?.specialties}
                                                setFieldValue={setFieldValue}
                                                saveOnStorage={() => saveOnStorage(values)}
                                                errors={errors?.specialties}
                                                hasErrors={
                                                   !values?.specialties?.specialtyNames?.length ||
                                                   (touched?.specialties && errors?.specialties)
                                                }
                                             ></AccordionCustom>
                                             <AccordionCustom
                                                title="Pedido de estudios"
                                                type="PE"
                                                expanded={expanded}
                                                handleChange={handleChange('PE')}
                                                formField={formField}
                                                values={values?.studies}
                                                setFieldValue={setFieldValue}
                                                saveOnStorage={() => saveOnStorage(values)}
                                                errors={errors?.studies}
                                                hasErrors={
                                                   !values?.studies?.studyNames?.length ||
                                                   (touched?.studies && errors?.studies)
                                                }
                                             ></AccordionCustom>
                                          </div>
                                          <div className="icon-btn-wrapper" style={{ display: "flex", flexDirection: "column", height: "130%", justifyContent: "space-evenly" }}>
                                             <Tooltip
                                                title={
                                                   disabledSubmit ? "Para finalizar la consulta, complete el registro de la misma en Registro de Consulta." : ""}
                                             >
                                                <Button
                                                   type="submit"
                                                   className={`btn ${disabledSubmit && 'btn--disabled'
                                                      }`}
                                                   endIcon={
                                                      isSubmitting && <CircularProgress size={24} />
                                                   }
                                                   onClick={() => {
                                                      if (disabledSubmit) {
                                                         setMessage("Para finalizar la consulta, complete el registro de la misma.")
                                                      } else {
                                                         handleSubmit(values, errors)
                                                      }
                                                   }
                                                   }
                                                >
                                                   Enviar consulta
                                                </Button>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    </Form>
                                 );
                              }}
                           </Formik>
                        </div>
                     </div>
                  </div>
               </div>
            </Box>
         </Modal>

      </>
   );
};


{/* */ }

export default Consultation;
